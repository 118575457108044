import type { ACF, Page } from 'types';
import type { CaptionedImage, ClassicCard } from '@braze/web-sdk';

import React, { useEffect, useState } from 'react';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { ToastProvider } from '@leafwell/components';
import { useShareImages } from 'hooks/useShareImages';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { useBrazeContext } from 'contexts/braze-context';
import { useEventsTracker } from 'contexts/eventsTracker';
import { request } from 'lib/rest';

const Footer = dynamic(() => import('./footer'));
const Header = dynamic(() => import('./header'));

type PageWrapProps = Pick<Page, 'title' | 'seo'> & {
  pageType?: ACF['pageType'];
  children: React.ReactElement | React.ReactElement[];
};

type ContentCard = CaptionedImage | ClassicCard;

const PageWrap: React.FC<PageWrapProps> = ({
  children,
  seo,
  pageType,
  title,
}) => {
  const [contentCards, setContentCards] = useState<ContentCard[] | null>(null);
  const { seoSettings, languageAlternates, siteTitle, campaigns } =
    useWordPressContext();
  const { locale, asPath, query } = useRouter();
  const { hasBrazeInitialized, fetchContentCards } = useBrazeContext();
  const { trackEvent } = useEventsTracker();
  const isCard = pageType === 'card';

  const image = useShareImages({
    type: 'full',
    title,
  });

  useEffect(() => {
    function getContentCards() {
      setTimeout(() => {
        // Fetch content cards on component mount
        fetchContentCards(fetchedCards => {
          setContentCards(fetchedCards as ContentCard[]);
        });
      }, 1_000);
    }

    if (query.utm_source && isCard) {
      // call api to determine if it's partner
      request(`/partner?slug=${query.utm_source}`).then(res => {
        if (res.data && res.data?.totalItems > 0) {
          setContentCards(null);
        } else {
          if (!hasBrazeInitialized) {
            return;
          }
          getContentCards();
        }
      });
    } else {
      if (!hasBrazeInitialized) {
        return;
      }
      getContentCards();
    }
  }, [query, hasBrazeInitialized]);

  useEffect(() => {
    if (!hasBrazeInitialized) {
      return;
    }

    if (asPath === '/') {
      trackEvent({
        event: 'website_visit_homepage',
      });
    } else if (asPath?.includes('/blog')) {
      trackEvent({
        event: 'website_visit_blog',
      });
    } else if (asPath?.includes('/medical-card')) {
      trackEvent({
        event: 'website_visit_medical_card',
      });
    } else if (asPath?.includes('/medical-conditions')) {
      trackEvent({
        event: 'website_visit_medical_conditions',
      });
    } else if (asPath?.includes('/research')) {
      trackEvent({
        event: 'website_visit_research',
      });
    } else if (asPath?.includes('/cannabis-strains')) {
      trackEvent({
        event: 'website_visit_strains',
      });
    } else if (asPath?.includes('/partner-with-leafwell')) {
      trackEvent({
        event: 'website_visit_partners',
      });
    } else if (asPath === '/about') {
      trackEvent({
        event: 'website_visit_about',
      });
    } else if (asPath === '/careers') {
      trackEvent({
        event: 'website_visit_careers',
      });
    } else if (asPath === '/contact-us') {
      trackEvent({
        event: 'website_visit_contact_us',
      });
    } else if (asPath === '/employers') {
      trackEvent({
        event: 'website_visit_employers',
      });
    } else if (asPath === '/cannabis-guidance') {
      trackEvent({
        event: 'website_visit_cannabis_guidance',
      });
    }
  }, [hasBrazeInitialized, asPath]);

  let cardObj: ContentCard = null;
  let showCampaign = false;

  if (campaigns?.length > 0 && contentCards?.length > 0) {
    for (let i = 0; i < campaigns?.length; i++) {
      const { contentCardId, showContentCard } = campaigns[i];

      cardObj = contentCards?.find(card => card.extras.ID === contentCardId);

      if (showContentCard?.includes('homepage') && asPath === '/') {
        showCampaign = true;
      } else {
        showCampaign = showContentCard?.some(path => asPath?.includes(path));
      }

      if (cardObj && showCampaign) {
        // break the loop, we found a match
        break;
      } else {
        cardObj = null;
        showCampaign = false;
      }
    }
  }

  return (
    <>
      <NextSeo
        key={`page-seo-${seo?.canonical}`}
        title={seo?.title ?? siteTitle}
        description={seo?.description}
        openGraph={{
          type: 'website',
          title: seo?.opengraph?.title,
          description: seo?.opengraph?.description,
          images: [image],
          url: seo?.canonical,
          site_name: siteTitle,
          locale,
        }}
        nofollow={seo?.metaRobotsNofollow || false}
        noindex={seo?.metaRobotsNoindex || false}
        robotsProps={{ noarchive: seo?.metaRobotsNoarchive || false }}
        canonical={seo?.canonical}
        twitter={{
          cardType: seoSettings?.social?.twitter?.cardType,
          handle: seoSettings?.social?.twitter?.authorFallback,
          site: seoSettings?.social?.twitter?.profile,
        }}
        facebook={{ appId: seoSettings?.social?.facebook?.appId }}
        languageAlternates={languageAlternates}
      />
      <ToastProvider>
        {pageType !== 'google_my_business' &&
          pageType !== 'vpd_landing' &&
          pageType !== 'webinar_landing' &&
          pageType !== 'affiliate_landing' && (
            <Header pageType={pageType} brazeContentCard={cardObj} />
          )}
        <main className="grow xl:pt-40 relative">{children}</main>
        {pageType !== 'google_my_business' && (
          <Footer
            showNavFooter={
              pageType !== 'affiliate_landing' &&
              pageType !== 'vpd_landing' &&
              pageType !== 'webinar_landing'
            }
          />
        )}
      </ToastProvider>
    </>
  );
};

export default PageWrap;
